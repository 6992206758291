import dayjs from 'dayjs'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { setFiltersAction } from '../../../store/reducers/filter/filterReducer'
import type { RootState } from '../../../store/store'
import { useAppDispatch } from '../../../store/store'
import { DatePicker } from '../../atoms/DatePicker'
import { QueryKeyENUM } from '../../atoms/Filters/ApplyFilters.model'
import { AgesFilter } from '../AgesFilter/AgesFilter'
import type { ItemType } from '../ApplyFilters.model'
import { DirectionsFilter } from '../DirectionsFilter/DirectionsFIlter'
import { KindEventFilter } from '../KindEventFilter/KindEventFilter'
import { MunicipalityFilter } from '../MunicipalityFilter/MunicipalityFilter'
import { OrganizationFilter } from '../OrganizationFilter/OrganizationFilter'
import { ProfileEventFilter } from '../ProfileEventFilter/ProfileEventFilter'
import { FilterTypeENUM } from './Filters.model'
import { SFiltersWrapp } from './FiltersCollection.styled'

interface IEventsFiltersProps {
  selectedFilters: Record<QueryKeyENUM, ItemType>
}

const defaultFormat = 'DD.MM.YYYY'

export const EventsFilters: FC<IEventsFiltersProps> = ({ selectedFilters }) => {
  const dispatch = useAppDispatch()
  const { eventsFilters } = useSelector((state: RootState) => state.filters)

  const setFilters = useCallback(
    (key: QueryKeyENUM, item: ItemType | null) => {
      dispatch(
        setFiltersAction({
          key: FilterTypeENUM.eventsFilters,
          filterKey: key,
          item,
        }),
      )
    },
    [dispatch],
  )

  const onChangeDate = useCallback(
    (date: string | null, key: QueryKeyENUM) => {
      setFilters(key, date ? { label: key, value: date } : null)
    },
    [setFilters],
  )

  const dateFrom = useMemo(
    () =>
      eventsFilters?.[QueryKeyENUM.dateFrom]
        ? dayjs(eventsFilters[QueryKeyENUM.dateFrom].value, defaultFormat)
        : dayjs(),
    [eventsFilters],
  )

  const dateTo = useMemo(
    () =>
      eventsFilters?.[QueryKeyENUM.dateTo]
        ? dayjs(eventsFilters[QueryKeyENUM.dateTo].value, defaultFormat)
        : dayjs(),
    [eventsFilters],
  )

  return (
    <SFiltersWrapp>
      <DirectionsFilter
        setFilters={setFilters}
        prevState={selectedFilters?.[QueryKeyENUM.directionId]}
      />
      <DatePicker
        value={dateFrom}
        onChange={(date) => onChangeDate(date, QueryKeyENUM.dateFrom)}
        title="Дата начала"
      />
      <DatePicker
        value={dateTo}
        onChange={(date) => onChangeDate(date, QueryKeyENUM.dateTo)}
        title="Дата окончания"
      />
      <ProfileEventFilter
        setFilters={setFilters}
        prevState={selectedFilters?.[QueryKeyENUM.profileId]}
      />
      <MunicipalityFilter
        setFilters={setFilters}
        prevState={selectedFilters?.[QueryKeyENUM.moId]}
      />
      <OrganizationFilter
        setFilters={setFilters}
        prevState={selectedFilters?.[QueryKeyENUM.unitID]}
      />
      <AgesFilter
        setFilters={setFilters}
        prevState={selectedFilters?.[QueryKeyENUM.age]}
      />
      <KindEventFilter
        setFilters={setFilters}
        prevState={selectedFilters?.[QueryKeyENUM.kind]}
      />
    </SFiltersWrapp>
  )
}
