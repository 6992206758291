import styled from 'styled-components'

import { getSpacing } from '../../../style/styleMixins/offset'

export const FilterWrapper = styled.div`
  box-sizing: border-box;
  padding: ${getSpacing([0.5, 0.75])};
  background-color: white;
  border: 1px solid #d9d9d9;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
`
