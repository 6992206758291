import type { QueryKeyENUM } from '../../atoms/Filters/ApplyFilters.model'
import type { ItemType } from '../ApplyFilters.model'

export enum FilterTypeENUM {
  eventsFilters = 'eventsFilters',
  programsFilters = 'programsFilters',
  talentsFilters = 'talentsFilters',
  talentEventsFilters = 'talentEventsFilters',
  newsFilters = 'newsFilters',
  unitsFilters = 'unitsFilters',
}

export interface IDefaultFilterProps {
  setFilters: (key: QueryKeyENUM, item: ItemType | null) => void
  prevState: ItemType
  queryKey?: QueryKeyENUM
  title?: string
}
