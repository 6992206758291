import React from 'react'

import Icon from '@ant-design/icons'

const LinesIconSVG = () => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M3 7H15V5H3M0 0V2H18V0M7 12H11V10H7V12Z" fill="black" />
  </svg>
)
export const LinesIcon = (props: any) => (
  <Icon component={LinesIconSVG} {...props} />
)
