import styled from 'styled-components'

import { getSpacing } from '../../style/styleMixins/offset'
import { defaultTextStyle } from '../../style/styleMixins/text'

export const SFilterBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(2)};
  padding-bottom: ${getSpacing([1])};
  border-bottom: 1px solid ${(p) => p.theme.colors.grey_300};

  .ant-select-selector {
    height: 36px;
    width: 100%;

    border-radius: ${(p) => p.theme.decoration.borderRadius.small};
    background: ${(p) => p.theme.colors.grey_000} !important;

    &:hover {
      transition: all 0.1s linear;
      border: 1px solid ${(p) => p.theme.colors.blue_500} !important;
    }
  }

  .ant-checkbox {
    &-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    &-inner {
      width: 30px;
      height: 30px;
      border: 1px solid black;

      ::after {
        width: 8px;
        height: 12px;
        margin-left: ${getSpacing(0.375)} !important;
      } // У голого antd там вообще тихий ужас, по-другому никак
    }
  }
`

export const STitle = styled.p`
  margin: 0;
  padding: 0;
  ${defaultTextStyle(9)}
`

export const SFilterList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${getSpacing(0.5)};
`

export const SFilterItem = styled.div<{ active: boolean }>`
  display: flex;
  padding: ${getSpacing([1, 2])};
  border-radius: ${(p) => p.theme.decoration.borderRadius.small};
  background-color: ${(p) =>
    p.active ? p.theme.colors.blueGray : p.theme.colors.grey_200};
  color: ${(p) =>
    p.active ? p.theme.colors.grey_000 : p.theme.colors.grey_500};
  cursor: pointer;
  ${defaultTextStyle(1)}
`

export const SHeaderWrapp = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SUnshortTitle = styled.p`
  color: ${(p) => p.theme.colors.blue_700};
  margin: 0;
  padding: 0;
  cursor: pointer;
`
