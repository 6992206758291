import { LinesIcon } from 'assets/img/logo/Lines'
import { Button } from 'components/atoms'
import { Modal } from 'components/atoms/Modal'
import { EventsFilters } from 'components/Filters/0_FilteraCollection/EventsFilters'
import { FilterTypeENUM } from 'components/Filters/0_FilteraCollection/Filters.model'
import { ProgramsFilters } from 'components/Filters/0_FilteraCollection/ProgramFilters'
import { useRouter } from 'next/router'
import type { ParsedUrlQuery } from 'querystring'
import type { FC, ReactNode } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pathENUM } from 'registrators/registrators'
import { clearFilters } from 'store/reducers/filter/filterReducer'
import type { RootState } from 'store/store'
import { generalTheme } from 'style'
import { queryParser } from 'utils/utils'

import {
  IconWrapp,
  SButtonSelection,
  SClearButton,
  SHeaderWrapp,
  SModal,
  SWrap,
} from '../../MainMenu.styled'

interface IFiltersMainMenuProps {
  pathToEventsFilter?: pathENUM
  pathToProgramsFilter?: pathENUM
  initialQuery?: ParsedUrlQuery
  programButtonDisabled?: boolean
  eventsButtonDisabled?: boolean
  initialActiveMenuButton?:
    | FilterTypeENUM.eventsFilters
    | FilterTypeENUM.programsFilters
  afterClearFilterCallback?: () => void
  disabled?: boolean
}

export const FiltersMainMenu: FC<IFiltersMainMenuProps> = ({
  pathToEventsFilter = pathENUM.events,
  pathToProgramsFilter = pathENUM.programs,
  initialQuery = {},
  programButtonDisabled = false,
  eventsButtonDisabled = false,
  initialActiveMenuButton = FilterTypeENUM.programsFilters,
  afterClearFilterCallback,
  disabled,
}) => {
  const router = useRouter()
  const dispatch = useDispatch()

  const filters = useSelector((state: RootState) => state.filters)

  const [visibility, setVisibility] = useState(false)
  const [changeFilterContent, setChangeFilterContent] = useState<
    FilterTypeENUM.eventsFilters | FilterTypeENUM.programsFilters
  >(initialActiveMenuButton)

  const onChangeContent = useCallback(
    (e: FilterTypeENUM.eventsFilters | FilterTypeENUM.programsFilters) => {
      setChangeFilterContent(e)
    },
    [],
  )

  const onVisibleHandleClick = useCallback(() => {
    setVisibility((prev) => !prev)
  }, [])

  const onClearFilters = useCallback(() => {
    dispatch(clearFilters())
    afterClearFilterCallback?.()
  }, [afterClearFilterCallback, dispatch])

  const applySelectedFilters = useCallback(() => {
    if (changeFilterContent === FilterTypeENUM.eventsFilters) {
      const selectedFilters = filters[FilterTypeENUM.eventsFilters]
      const queryList = {
        ...queryParser(selectedFilters),
        only_future_dates: 'true',
        all_dates: 'false',
        sort_by: JSON.stringify(['-date_from']),
      }
      router.push({
        pathname: pathToEventsFilter,
        query: {
          ...initialQuery,
          ...queryList,
        },
      })
      onVisibleHandleClick()
    }
    if (changeFilterContent === FilterTypeENUM.programsFilters) {
      const selectedFilters = filters[FilterTypeENUM.programsFilters]
      const queryList = queryParser(selectedFilters)
      router.push({
        pathname: pathToProgramsFilter,
        query: {
          ...initialQuery,
          ...queryList,
        },
      })
      onVisibleHandleClick()
    }
  }, [
    changeFilterContent,
    filters,
    initialQuery,
    onVisibleHandleClick,
    pathToEventsFilter,
    pathToProgramsFilter,
    router,
  ])

  const filtersContent: Record<
    FilterTypeENUM.eventsFilters | FilterTypeENUM.programsFilters,
    ReactNode
  > = useMemo(
    () => ({
      eventsFilters: (
        <EventsFilters
          selectedFilters={filters[FilterTypeENUM.eventsFilters]}
        />
      ),
      programsFilters: (
        <ProgramsFilters
          selectedFilters={filters[FilterTypeENUM.programsFilters]}
        />
      ),
    }),
    [filters],
  )

  useEffect(() => {
    setChangeFilterContent(initialActiveMenuButton)
  }, [initialActiveMenuButton])

  return (
    <>
      <IconWrapp onClick={onVisibleHandleClick}>
        <LinesIcon />
      </IconWrapp>
      {!disabled && visibility && (
        <Modal
          modalContentMaxWidth={generalTheme.maxWidthModal.default}
          header={
            <SHeaderWrapp>
              <SClearButton onClick={onClearFilters}>Очистить</SClearButton>
              <SModal>Фильтры</SModal>
            </SHeaderWrapp>
          }
          open
          onClose={onVisibleHandleClick}
          footer={<Button onClick={applySelectedFilters}>Применить</Button>}>
          <SWrap>
            <SButtonSelection
              disabled={programButtonDisabled}
              active={changeFilterContent === FilterTypeENUM.programsFilters}
              onClick={() => onChangeContent(FilterTypeENUM.programsFilters)}>
              Программы
            </SButtonSelection>
            <SButtonSelection
              disabled={eventsButtonDisabled}
              active={changeFilterContent === FilterTypeENUM.eventsFilters}
              onClick={() => onChangeContent(FilterTypeENUM.eventsFilters)}>
              Мероприятия
            </SButtonSelection>
          </SWrap>
          {filtersContent[changeFilterContent]}
        </Modal>
      )}
    </>
  )
}
