import { Spin } from 'antd'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { filtersAPI } from '../../../axios/axios'
import { QueryKeyENUM } from '../../atoms/Filters/ApplyFilters.model'
import type { IFilterProps, ItemType } from '../ApplyFilters.model'
import { FilterItem } from '../components/FilterItem/FilterItem'
import { SFilterBlock, SFilterList, STitle } from '../Filters.styled'
import type { IKind } from '../KindFilter.model'
import { KindTypeEventENUM } from '../KindFilter.model'

export const KindEventFilter: FC<IFilterProps> = ({
  setFilters,
  prevState,
}) => {
  const [kinds, setKinds] = useState<IKind[]>([])
  const [fetch, setFetch] = useState(false)
  const [selectedKind, setSelectedKind] = useState<ItemType | null>(prevState)

  const handleSetFetch = useCallback((value: boolean) => {
    setFetch(value)
  }, [])

  const handleSetItem = useCallback(
    (item: ItemType) => {
      if (selectedKind?.id === item.id) {
        setFilters(QueryKeyENUM.kind, null)
        setSelectedKind(null)
      } else {
        setSelectedKind(item)
        setFilters(QueryKeyENUM.kind, { ...item, value: item.id })
      }
    },
    [selectedKind, setFilters],
  )

  const content = useMemo(() => {
    if (fetch) {
      return <Spin />
    }
    if (!fetch && kinds) {
      return kinds.map((d) => (
        <FilterItem
          key={d.id}
          item={d}
          setItem={handleSetItem}
          active={selectedKind?.id === d.id}
        />
      ))
    }
    return []
  }, [fetch, handleSetItem, kinds, selectedKind?.id])

  const getKinds = useCallback(async () => {
    try {
      handleSetFetch(true)
      const res = await filtersAPI.getKinds()
      handleSetFetch(false)
      const { data } = res.data
      const typesKind = Object.entries(data)
        .map(([key, value]: [key: string, value: any]) => ({
          name: KindTypeEventENUM[key as keyof typeof KindTypeEventENUM],
          type: key,
          id: value,
        }))
        .slice(0, 2) // Для фильтров на программах нужны только первые 2 фильтра
      setKinds(typesKind)
    } catch (e) {
      console.error(e)
    }
  }, [handleSetFetch])

  useEffect(() => {
    getKinds()
  }, [getKinds])

  useEffect(() => {
    !prevState && setSelectedKind(prevState)
  }, [prevState])

  return (
    <SFilterBlock>
      <STitle>Стоимость</STitle>
      <SFilterList>{content}</SFilterList>
    </SFilterBlock>
  )
}
