/* eslint-disable */

import lodash from 'lodash'

export enum QueryKeyENUM {
  directionId = 'direction_id',
  profileId = 'profile_id',
  age = 'age',
  kind = 'kind',
  programDurationInHours = 'programDurationInHours',
  localityGUID = 'localityGUID',
  unitID = 'unit_id',
  dateFrom = 'date_from',
  dateTo = 'date_to',
  resultId = 'result_id',
  year = 'year',
  districtId = 'district_id',
  eventName = 'event_name',
  fio = 'surname_firstname',
  talentEventDirectionId = 'direction_gifted_id',
  dateBegin = 'date_begin',
  dateEnd = 'date_end',
  eventLevel = 'level',
  eventKind = 'kind',
  eventProgramProfiles = 'kind_direction_gifted_id',
  realizationForm = 'realization_form',
  tags = 'tags',
  date = 'date',
  municipalityDistrict = 'municipalityDistrict',
  moId = 'mo_id',
  in_pfdo = 'in_pfdo',
  per_page = 'per_page',
}

export enum DirectionsENUM {
  NATURAL_SCIENCE = 'Естественные науки',
  TECHNICAL = 'Технические',
  TOURIST = 'Туризм',
  ARTISTIC = 'Актёрские',
  SOCIAL = 'Социальные',
  SPORTS = 'Спортивные',
}

// моки для фильтрации по квери параметрам, вэлью используется как значение для отдельного квери параметра
export const Directions = [
  { label: DirectionsENUM.NATURAL_SCIENCE, value: 1 },
  { label: DirectionsENUM.TECHNICAL, value: 2 },
  { label: DirectionsENUM.TOURIST, value: 3 },
  { label: DirectionsENUM.ARTISTIC, value: 4 },
  { label: DirectionsENUM.SOCIAL, value: 5 },
  { label: DirectionsENUM.SPORTS, value: 6 },
]

export const Profiles = [
  { label: 'Волонтерская работа', value: 5 },
  { label: 'Профессиональная ориентация', value: 5 },
  { label: 'Психология', value: 5 },
  { label: 'Социальная адаптация', value: 5 },
  { label: 'Школа актива и/или РДШ', value: 5 },
  { label: 'Военно - патриотические объединения', value: 5 },
  { label: 'Биология', value: 1 },
  { label: 'Математические предметы', value: 1 },
  { label: 'Медицина', value: 1 },
  { label: 'Фармакология', value: 1 },
]

// Экранирование нужно для сортировки мероприятий и программ

const averageScore = '"average_score"'
const decreaseScore = '"-average_score"'
const costFree = '"cost"'
const costPaid = '"-cost"'
const profileAlphabet = '"profile__name"'
const profileReverseAlphabet = '"-profile__name"'
const directionAlphabet = '"-direction__name"'
const directionReverseAlphabet = '"direction__name"'
const nameAlphabet = '"title"'
const nameReverseAlphabet = '"-title"'

export type SortParamsForChosenPage = {
  title: string
  param: string
}

export const sortBy = [
  { title: 'Название А-Я', param: nameAlphabet },
  { title: 'Название Я-А', param: nameReverseAlphabet },
  { title: 'Направленность А-Я', param: directionAlphabet },
  { title: 'Направленность Я-А', param: directionReverseAlphabet },
  { title: 'Профиль А-Я', param: profileAlphabet },
  { title: 'Профиль Я-А', param: profileReverseAlphabet },
  { title: 'Сначала платные', param: costPaid },
  { title: 'Сначала бесплатные', param: costFree },
  { title: 'По возрастанию рейтинга', param: averageScore },
  { title: 'По убыванию рейтинга', param: decreaseScore },
]

export const sortParams = (param?: SortParamsForChosenPage[]) => {
  if (!param || !param.length) return sortBy

  return lodash.map(sortBy, (item) => {
    const foundItem = lodash.find(param, { title: item.title })
    return foundItem || item
  })
}
